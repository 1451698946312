import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CustomProjectsCardsSmall = () => {
	const data = useStaticQuery(graphql`
		query {
			allCardsCustomProjectsJson {
				edges {
					node {
						image {
							childImageSharp {
								original {
									src
								}
							}
						}
						gameFor
						title
						text
					}
				}
			}
		}
	`);
	const responsive = {
		tablet: {
			breakpoint: { max: 768, min: 576 },
			items: 1,
			slidesToSlide: 1,
		},
		mobile: {
			breakpoint: { max: 576, min: 0 },
			items: 1,
			slidesToSlide: 1,
		},
	};
	return (
		<div className="customProjectsSmallCardsWrapper">
			<Carousel
				className="carousel"
				responsive={responsive}
				autoPlay={false}
				customTransition="all .1s"
				transitionDuration={100}
				infinite={true}
				removeArrowOnDeviceType={["tablet", "mobile"]}
				draggable={true}
                showDots={true}
                dotListClass="custom-dot-list-style"
			>
				{data.allCardsCustomProjectsJson.edges.map((item, index) => {
					return (
						<div key={index} className="customProjectsSmallCardWrapper">
							<div className="customProjectsSmallCard">
							    <div className="customProjectsSmallCardImageWrapper">
							        <img
        								className="customProjectsSmallCardImage"
        								src={item.node.image.childImageSharp.original.src}
        								alt={item.node.title}
        							/>
							    </div>
    							<div className="customProjectsSmallCardTextWrapper">
    								<p className="customProjectsSmallCardGameFor">
    									{item.node.gameFor}
    								</p>
    								<p className="customProjectsSmallCardTitle">
    									{item.node.title}
    								</p>
    
    								<p className="customProjectsSmallCardText">{item.node.text}</p>
    							</div>
							</div>
						</div>
					);
				})}
			</Carousel>
		</div>
	);
};

export default CustomProjectsCardsSmall;
