import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const CustomProjectsCards = () => {
	const data = useStaticQuery(graphql`
		query {
			allCardsCustomProjectsJson {
				edges {
					node {
						image {
							childImageSharp {
								original {
									src
								}
							}
						}
						gameFor
						title
						text
					}
				}
			}
		}
	`);
	return (
		<div className="customProjectsCardsWrapper">
			{data.allCardsCustomProjectsJson.edges.map((item, index) => {
				return (
					<div key={index} className="customProjectsCard">
						<img
						    className="customProjectsCardImage"
							src={item.node.image.childImageSharp.original.src}
							alt={item.node.title}
						/>
						<div className="customProjectsCardTextWrapper">
							<p className="customProjectsCardGameFor">{item.node.gameFor}</p>
							<p className="customProjectsCardTitle">{item.node.title}</p>
	
							<p className="customProjectsCardText">{item.node.text}</p>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default CustomProjectsCards;
