import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import TeaserButton from "../styled/TeaserButton";

const TeamBuildingCards = () => {
	const data = useStaticQuery(graphql`
		query {
			allCardsTeamBuildingJson {
				edges {
					node {
						image {
							childImageSharp {
								original {
									src
								}
							}
						}
						title
						text
						gameplay
						path
						videoId
					}
				}
			}
		}
	`);
	return (
		<div className="teamBuildingCardsWrapper">
			{data.allCardsTeamBuildingJson.edges.map((item, index) => {
				return (
					<div
						key={index}
						className="teamBuildingCard"
						style={{
							backgroundImage: `url(${item.node.image.childImageSharp.original.src})`,
							backgroundPosition: "center",
							/* backgroundSize: "cover",
							backgroundRepeat: "no-repeat", */
						}}
					>
						<p className="teamBuildingCardTitle">{item.node.title}</p>
						<ul className="teamBuildingCardGamePlayWrapper">
							<li className="teamBuildingCardGamePlay">{item.node.gameplay}</li>
						</ul>
						<ul className="teamBuildingCardListWrapper">
							{item.node.text.map((item, index) => {
								return (
									<li key={index} className="teamBuildingCardList">
										<span>{item}</span>
									</li>
								);
							})}
						</ul>
						<div className="teamBuildingCardsButtons">
							<Link to={item.node.path} className="teamBuildingCardLearnMore" aria-label={item.node.title}>
								Learn More <span className="descriptiveTextForSeo">{item.node.title}</span>
							</Link>
							<TeaserButton videoId={item.node.videoId} />
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default TeamBuildingCards;
