import React, {useRef, useEffect} from "react";
import styled from "styled-components";
import {gsap} from "gsap";

const Wrapper = styled.div`
visibility: hidden;
	position: absolute;
	opacity: 0.75;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: flex-end;
	padding-bottom: 15vh;
	@media only screen and (min-width: 1920px) {
		width: 1000px;
	    height: auto;
		padding-bottom: 250px;
	}
`;

const Item1 = styled.div`
	position: absolute;
	left: 0%;
	right: 79.31%;
`;
const Item2 = styled.div`
	position: absolute;
	left: 17.34%;
	right: 61.97%;
`;
const Item3 = styled.div`
	position: absolute;
	left: 35.83%;
	right: 44.06%;
`;
const Item4 = styled.div`
	position: absolute;
	left: 52.94%;
	right: 26.37%;
`;



const Logo = () => {
	let animateWrapper = useRef(null);
	let animateItem1 = useRef(null);
	let animateItem2 = useRef(null);
	let animateItem3 = useRef(null);
	let animateItem4 = useRef(null);

	useEffect(() => {
		gsap.from(animateWrapper.current, {duration: .5, autoAlpha: 0});
		gsap.fromTo(animateItem1.current, {opacity: 0}, {duration: .55, opacity: 1, delay: 1})
		gsap.fromTo(animateItem2.current, {x:-333, opacity: 0}, {duration: .55, opacity: 1, x:-333, delay: 1})
		gsap.fromTo(animateItem3.current, {x:-665, opacity: 0}, {duration: .55, opacity: 1, x:-665, delay: 1})
		gsap.fromTo(animateItem4.current, {x:-1000, opacity: 0}, {duration: .25, opacity: 1, x:-1000, delay: .75})

		gsap.fromTo(animateItem2.current, {x:-333}, {duration: .55, x:0, delay: 1})
		gsap.fromTo(animateItem3.current, {x:-665}, {duration: .55, x:0, delay: 1})
		gsap.fromTo(animateItem4.current, {x:-999}, {duration: .55, x:0, delay: 1})

	/* 	gsap.from(animateWrapper.current, {duration: .5, autoAlpha: 0});
		gsap.fromTo(animateItem1.current, {opacity: 0, rotation: -90}, {duration: 1, rotation: 0, opacity: 1,  delay: 1})
		gsap.fromTo(animateItem2.current, {x:-300, opacity: 0, rotation: 180}, { rotation: 0, opacity: 1, duration: 1.2, x:0, delay: 1})
		gsap.fromTo(animateItem3.current, {x:-600, opacity: 0, rotation: 360}, { rotation: 0, opacity: 1, duration: 1.4, x:0, delay: 1})
		gsap.fromTo(animateItem4.current, {x:-900, opacity: 0, rotation: 90}, { rotation: 0, opacity: 1, duration: 1.6, x:0, delay: 1}) */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animateWrapper]);
	
	return (
		<>
			<Wrapper ref={animateWrapper}>
				<Item1 ref={animateItem1}>
					<svg width="100%" viewBox="0 0 398 398">
						<path
							d="M397.236 0H0V397.236H397.236V0Z"
							fill="url(#paint0_linear1)"
						/>
						<defs>
							<linearGradient
								id="paint0_linear1"
								x1="0"
								y1="198.616"
								x2="397.232"
								y2="198.616"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#FBF61F" />
								<stop offset="0.19" stopColor="#FCD433" />
								<stop offset="0.4" stopColor="#FEB743" />
								<stop offset="0.61" stopColor="#FEA24F" />
								<stop offset="0.81" stopColor="#FF9557" />
								<stop offset="1" stopColor="#FF9159" />
							</linearGradient>
						</defs>
					</svg>
				</Item1>
				<Item2 ref={animateItem2}>
					<svg width="100%" viewBox="0 0 398 398">
						<path
							d="M116.316 0L0.879883 116.505V280.294L116.316 397.236H281.203L398.116 282.09V114.834L283.606 0H116.316Z"
							fill="url(#paint0_linear2)"
						/>
						<defs>
							<linearGradient
								id="paint0_linear2"
								x1="0.879883"
								y1="198.618"
								x2="398.116"
								y2="198.618"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#FD7275" />
								<stop offset="1" stopColor="#E53E90" />
							</linearGradient>
						</defs>
					</svg>
				</Item2>
				<Item3 ref={animateItem3}>
					<svg width="100%" viewBox="0 0 398 398">
						<path
							d="M263.008 0H125.033C56.5042 0 0.950684 55.8175 0.950684 124.672V272.564C0.950684 341.418 56.5042 397.236 125.033 397.236H263.008C331.537 397.236 387.091 341.418 387.091 272.564V124.672C387.091 55.8175 331.537 0 263.008 0Z"
							fill="url(#paint0_linear3)"
						/>
						<defs>
							<linearGradient
								id="paint0_linear3"
								x1="0.950684"
								y1="198.618"
								x2="387.091"
								y2="198.618"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#CB13B2" />
								<stop offset="0.21" stopColor="#C31BB5" />
								<stop offset="0.56" stopColor="#AB30BF" />
								<stop offset="0.98" stopColor="#8652CE" />
								<stop offset="1" stopColor="#8454CF" />
							</linearGradient>
						</defs>
					</svg>
				</Item3>
				<Item4 ref={animateItem4}>
					<svg width="100%" viewBox="0 0 398 398">
						<path
							d="M199.01 399.455C308.704 399.455 397.628 310.034 397.628 199.728C397.628 89.4211 308.704 0 199.01 0C89.3165 0 0.39209 89.4211 0.39209 199.728C0.39209 310.034 89.3165 399.455 199.01 399.455Z"
							fill="url(#paint0_linear4)"
						/>
						<defs>
							<linearGradient
								id="paint0_linear4"
								x1="0.39209"
								y1="199.728"
								x2="397.628"
								y2="199.728"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#66E0FC" />
								<stop offset="0.07" stopColor="#63D9F9" />
								<stop offset="0.46" stopColor="#55B9ED" />
								<stop offset="0.78" stopColor="#4CA6E6" />
								<stop offset="1" stopColor="#499FE3" />
							</linearGradient>
						</defs>
					</svg>
				</Item4>
			</Wrapper>
		</>
	);
};

export default Logo;
