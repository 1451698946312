import React, { useRef, useState, useEffect } from "react";
import SEO from "../components/seo/SEO";
import { Link } from "gatsby";
import Navbar from "../components/layout/Navbar";
import Form from "../components/form/Form";
import Logo from "../components/logo/Logo";
import LogoPartner from "../components/logo/LogoPartner";
import LogoPartnerSmall from "../components/logo/LogoPartnerSmall";
import LogoAbout from "../components/logo/LogoAbout";
import LogoAboutSmall from "../components/logo/LogoAboutSmall";
import TeamBuildingCards from "../components/cards/TeamBuildingCards";
import CustomProjectsCards from "../components/cards/CustomProjectsCards";
import CustomProjectsCardsSmall from "../components/cards/CustomProjectsCardsSmall";

import { gsap } from "gsap";
import arrow from "../images/index/arrow.png";

const Home = () => {
	let heroWrapper = useRef(null);
	let textWrapper = useRef(null);

	useEffect(() => {
		gsap.from(heroWrapper.current, { duration: 2, autoAlpha: 0 });
		gsap.from(textWrapper.current, { duration: 2, opacity: 1 });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [heroWrapper]);

	const [changeHeight, setChangeHeight] = useState(true);

	const handleHeight = () => {
		setChangeHeight((changeHeight) => !changeHeight);
	};

	return (
		<>
			<SEO title="Home" description="This is home page" />
			<Navbar />
			<div className="overlay"></div>
			<div className="indexPage">
				<section className="heroSection" ref={heroWrapper}>
					<div className="container">
						<div className="heroItems" ref={textWrapper}>
							<div className="heroLeft">
								<p className="heroTitle titles-dark">
									Transforming organizational challenges into creative solutions
								</p>
								<p className="heroSubTitle">
									Bridging the gap that divides remote work teams in the online
									space.
								</p>
							</div>
							<div className="heroRight">
								<div className={changeHeight ? "minHeight" : "maxHeight"}>
									<p className="heroRightText texts-dark">
										The transition from office spaces to working from home has
										accelerated the growth of the digital economy. But as all
										great change, it has also given rise to new challenges.
									</p>
									<p className="heroRightText texts-dark">
										With office contact and in-person team building events out
										of the picture, finding new ways to boost employee bonding
										and maintain team cohesion has become a hot topic in HR.
									</p>
									<p className="heroRightText texts-dark">
										Remote teams face various challenges such as lack of
										structure, different communication styles, lack of trust
										among team members and weak company culture.
									</p>
									<p className="heroRightText texts-dark">
										We tackle the challenges of work moving online by enabling
										team-building to follow!
									</p>
								</div>
								<div className="changeHeightButtonWrapper">
									<button className="changeHeightButton" onClick={handleHeight}>
										{changeHeight ? "Read more" : "Close"}
										<img 
										src={arrow} 
										className={changeHeight ? "" : "arrowUp"}
										alt="arrow" />
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="backgroundLogo">
						<Logo />
					</div>
					
				</section>

				<section className="teamBuildingSection" id="teamBuildingGames">
					<div className="container">
						<div className="teamBuildingTextWrapper">
							<div className="teamBuildingTextLeft">
								<p className="teamBuildingTitle titles-light">
									Team Building Games
								</p>
							</div>
							<div className="teamBuildingTextRight">
								<p className="teamBuildingText texts-light">
									Our virtual team-building games can be played online from
									anywhere in the world. All you need is an internet connection
									and a video conferencing tool of your choice.
								</p>
								<p className="teamBuildingText texts-light">
									Bring your team closer together through engaging virtual
									interaction that leaves everyone feeling included. Help them
									stay connected and on top of their game, even in the current
									contactless world.
								</p>
							</div>
						</div>
						<div className="teamBuildingCardsWrapper">
							<TeamBuildingCards />
						</div>
					</div>
				</section>

				<section className="partnerSection">
					<div className="container">
						<div className="partnerSectionContentWrapper">
							<div className="partnerTextWrapper">
								<div className="titles-dark">
									<p>Become a Partner</p>
								</div>
								<div className="partnerText texts-dark">
									<p>
										Are you interested in running our team building games
										independently for your own clients? Write to us in the
										contact form below and you can license our products to
										organize virtual team building events and run your own games
										for corporate clients.
									</p>
								</div>

								<Link to="/#contactForm" className="partnerButton">
									Get in touch
								</Link>
							</div>
							<div className="partnerLogoWrapper">
								<LogoPartner />
							</div>
							<div className="partnerSmallAnimationWrapper">
								<LogoPartnerSmall />
							</div>
						</div>
					</div>
				</section>

				<section className="customSection" id="customProjects">
					<div className="container">
						<div className="customSectionTextWrapper">
							<div className="titles-light">
								<p>Custom Projects and Consulting</p>
							</div>
							<div className="customText texts-light">
								<p>
									We also create custom solutions that can be tailored to suit
									your company’s needs. Our team has experience in designing
									immersive games for TV shows, as well as games for employee
									training in large corporations. We also specialize in
									assisting companies with their branding, visual identity,
									marketing, franchising and licensing. Below are some of the
									custom projects and solutions for major international brands
									our team has worked on.
								</p>
							</div>
						</div>
						<div className="customProjectsBig">
							<CustomProjectsCards />
						</div>
						<div className="customProjectsSmall">
							<CustomProjectsCardsSmall />
						</div>
					</div>
				</section>

				<section className="aboutUsSection">
					<div className="container">
						<div className="aboutUsComponent">
							<div className="aboutUsAnimationWrapper">
								<LogoAbout />
							</div>
							<div className="aboutUsSmallAnimationWrapper">
								<LogoAboutSmall />
							</div>
							<div className="aboutUsTextWrapper">
								<p className="aboutUsTitle titles-dark">About us</p>
								<div className="aboutUsText">
									<p className="texts-dark">
										We create virtual team building solutions for remote work
										teams. Combining real-world scenarios with entertaining
										puzzles of different levels of immersion, we tackle
										organizational and HR challenges head-on with creative
										game-based solutions.
									</p>
									<p className="texts-dark">
										Our creative team has 10 years of escape room industry
										experience, from puzzle and game design to building and
										operating an international escape room franchise with over
										30 locations.
									</p>
									<p className="texts-dark">
										Adapting our diverse skill sets to the massive changes in
										how we do business today, we bring the essence of the escape
										room experience into the virtual corporate team building
										world.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="formSection" id="contactForm">
					<div className="container">
						<div className="formTitleWrapper">
							<p className="formTitle titles-dark">Request More Info</p>
						</div>
						<div className="formWrapper">
							<Form />
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default Home;
