import React, { useState } from "react";
import styled from "styled-components";
import ModalVideo from "react-modal-video";
import playOrange from "../../images/videoButtons/playOrange.png";

const BtnLink = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ff9259;;
	font-family: DM Sans;
	font-weight: bold;
	font-size: 1.3rem;
	line-height: 15px;
	cursor: pointer;
	transition: all 0.4s ease-in-out;
	img {
		width: 1.14rem !important;
		margin-right: 1rem;
	}
	&:hover {
		opacity: 0.7;
	}
	@media only screen and (max-width: 1284px) {
		font-size: 1.2rem;
	}
	@media only screen and (max-width: 576px) {
		font-size: 1.1rem;
	}
	@media only screen and (max-width: 340px) {
        font-size: 4vw;
	}
`;

const TeaserButton = (props) => {
	const [isOpen, setOpen] = useState(false);
	return (
		<>
			<ModalVideo
				channel="vimeo"
				autoplay
				isOpen={isOpen}
				videoId={props.videoId}
				muted={true}
				onClose={() => setOpen(false)}
			/>
			<BtnLink onClick={() => setOpen(true)}>
				<img src={playOrange} alt="play button" />
				<p>Play Teaser</p>
			</BtnLink>
		</>
	);
};

export default TeaserButton;
