import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const WrapperAbout = styled.div`
	visibility: hidden;
	position: relative;
	opacity: 0.75;
	bottom: 0;
	right: 0;
`;

const LogoPartner = () => {
	let animateWrapperAbout = useRef(null);
	let animateItem1About = useRef(null);
	let animateItem2About = useRef(null);
	let animateItem3About = useRef(null);
	let animateItem4About = useRef(null);

	useEffect(() => {
		gsap.from(animateWrapperAbout.current, { duration: 0.5, autoAlpha: 0 });
		gsap.from(animateItem1About.current, {
			scrollTrigger: {
				trigger: animateItem1About.current,
				toggleActions: "restart none none reverse",
				start: "top 80%"
			},
			duration: .5,
			opacity: 0,
			scale: 0,
			y: 100,
		});
		gsap.from(animateItem2About.current, {
			scrollTrigger: {
				trigger: animateItem1About.current,
				toggleActions: "restart none none reverse",
				start: "top 80%"
			},
			duration: .5,
			opacity: 0,
			scale: 0.5,
			x: 100,
		});
		gsap.from(animateItem3About.current, {
			scrollTrigger: {
				trigger: animateItem1About.current,
				toggleActions: "restart none none reverse",
				start: "top 80%"
			},
			duration: .5,
			opacity: 0,
			scale: .5,
			y: -100,
		});
		gsap.from(animateItem4About.current, {
			scrollTrigger: {
				trigger: animateItem1About.current,
				toggleActions: "restart none none reverse",
				start: "top 80%"
			},
			duration: .5,
			opacity: 0,
			scale: 0.5,
			x: -100,
		});

		/* gsap.fromTo(animateItem1About.current, {opacity: 0, scale: .5, y:100}, {duration: 1, opacity: 1, scale: 1, y:0, delay: .6})
		gsap.fromTo(animateItem2About.current, {opacity: 0, scale: .5, x:100}, {duration: 1, opacity: 1, scale: 1, x:0, delay: .6})
		gsap.fromTo(animateItem3About.current, {opacity: 0, scale: .5, y:-100}, {duration: 1, opacity: 1, scale: 1, y:0, delay: .6})
		gsap.fromTo(animateItem4About.current, {opacity: 0, scale: .5, x:-100}, {duration: 1, opacity: 1, scale: 1, x:0, delay: .6})


		gsap.fromTo(animateItem1About.current, {opacity: 1, y:0}, {duration: 1, opacity: 0, y:-100, delay: 3})
		gsap.fromTo(animateItem2About.current, {opacity: 1, x:0}, {duration: 1, opacity: 0, x:-100, delay: 3})
		gsap.fromTo(animateItem3About.current, {opacity: 1, y:0}, {duration: 1, opacity: 0, y:100, delay: 3})
		gsap.fromTo(animateItem4About.current, {opacity: 1, x:0}, {duration: 1, opacity: 0, x:100, delay: 3}) */
	}, [animateItem1About]);

	return (
		<>
			<WrapperAbout ref={animateWrapperAbout}>
				<svg
					width="310"
					height="315"
					viewBox="0 0 310 315"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						ref={animateItem1About}
						d="M159.025 0.414062H0V167.196H159.025V0.414062Z"
						fill="url(#aboutId1)"
					/>
					<path
						ref={animateItem2About}
						d="M109.266 314.807H49.7593C22.3872 314.807 0 292.208 0 264.576V203.427C0 175.796 22.3872 153.196 49.7593 153.196H109.266C136.638 153.196 159.025 175.796 159.025 203.427V264.576C159.025 292.208 136.638 314.807 109.266 314.807Z"
						fill="url(#aboutId2)"
					/>
					<path
						ref={animateItem3About}
						d="M193.126 0.414062L145.025 48.5505V118.337L193.126 167.196H262.088L309.222 120.732V48.5505L262.39 0.414062H193.126Z"
						fill="url(#aboutId3)"
					/>
					<path
						ref={animateItem4About}
						d="M225.83 314.807C270.458 314.807 306.636 278.629 306.636 234.002C306.636 189.374 270.458 153.196 225.83 153.196C181.203 153.196 145.025 189.374 145.025 234.002C145.025 278.629 181.203 314.807 225.83 314.807Z"
						fill="url(#aboutId4)"
					/>
					<defs>
						<linearGradient
							id="aboutId1"
							x1="6.72159e-07"
							y1="83.8067"
							x2="159.021"
							y2="83.8067"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#FBF61F" />
							<stop offset="0.1928" stopColor="#FCD433" />
							<stop offset="0.4002" stopColor="#FEB743" />
							<stop offset="0.606" stopColor="#FEA24F" />
							<stop offset="0.8076" stopColor="#FF9557" />
							<stop offset="1" stopColor="#FF9159" />
						</linearGradient>
						<linearGradient
							id="aboutId2"
							x1="0"
							y1="234.006"
							x2="159.032"
							y2="234.006"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#CB13B2" />
							<stop offset="0.2144" stopColor="#C31BB5" />
							<stop offset="0.5557" stopColor="#AB30BF" />
							<stop offset="0.9791" stopColor="#8652CE" />
							<stop offset="1" stopColor="#8454CF" />
						</linearGradient>
						<linearGradient
							id="aboutId3"
							x1="145.024"
							y1="83.8044"
							x2="309.227"
							y2="83.8044"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#FD7275" />
							<stop offset="1" stopColor="#E53E90" />
						</linearGradient>
						<linearGradient
							id="aboutId4"
							x1="145.024"
							y1="234.005"
							x2="306.643"
							y2="234.005"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#66E0FC" />
							<stop offset="0.0715242" stopColor="#63D9F9" />
							<stop offset="0.4552" stopColor="#55B9ED" />
							<stop offset="0.7784" stopColor="#4CA6E6" />
							<stop offset="1" stopColor="#499FE3" />
						</linearGradient>
					</defs>
				</svg>
			</WrapperAbout>
		</>
	);
};

export default LogoPartner;
